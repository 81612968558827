import React from 'react';
import { navigate, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  standardColours,
  fontSize,
  standardTransition,
} from '../styles';
import { Link } from './ui';
import { buildUrl } from '../utils';

const StyledEquipmentCategoryCard = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  height: 100%;
  background: ${({ alternative }) =>
    alternative === true
      ? brandColours.primary
      : alternative === 'grad'
      ? `linear-gradient(165deg, ${brandColours.secondary} 0%, ${brandColours.secondary} 50%, ${brandColours.primary} 100%);`
      : brandColours.secondary};
  cursor: pointer;
  transition: ${standardTransition('transform')};

  ${({ inFeaturedCategories }) => {
    if (!inFeaturedCategories) {
      return css`
        ${minBreakpointQuery.small`
          padding-top: 30px;
          padding-bottom: 30px;
        `}

        ${minBreakpointQuery.large`
          padding-top: 40px;
          padding-bottom: 40px;
        `}
      `;
    }
  }};

  ${({ isMoreLink }) => {
    if (isMoreLink) {
      return css`
        min-height: 160px;

        ${minBreakpointQuery.tsmall`
          grid-column: span 2;
          min-height: 200px;
        `}

        ${minBreakpointQuery.medium`
          grid-column: span 1;
        `}
      `;
    }
  }};

  &:hover {
    transform: scale(1.025);
  }
`;

const StyledImageWrapper = styled.div`
  ${({ inFeaturedCategories }) => {
    if (inFeaturedCategories) {
      return css`
        margin: auto 20px;

        ${minBreakpointQuery.medium`
          position: absolute;
          top: ${({ firstInFeaturedCategories }) =>
            firstInFeaturedCategories ? '-40px' : '-20px'};;
          right: ${({ firstInFeaturedCategories }) =>
            firstInFeaturedCategories ? '-20px' : '-10px'};
          bottom: 0;
          margin-right: 0;
          margin-left: 0;
        `}

        ${({ firstInFeaturedCategories }) => {
          if (firstInFeaturedCategories) {
            return css`
              ${minBreakpointQuery.medium`
                left: -20px;
              `}
            `;
          } else {
            return css`
              ${minBreakpointQuery.medium`
                width: 55%;
              `}
            `;
          }
        }};
      `;
    } else {
      return css`
        margin: auto 20px;

        ${minBreakpointQuery.small`
          margin-right: 30px;
          margin-left: 30px;
        `}

        ${minBreakpointQuery.large`
          margin-right: 40px;
          margin-left: 40px;
        `}
      `;
    }
  }};
`;

const StyledHeading = styled.h3`
  position: relative;
  margin-top: auto;
  padding: 15px 0 0 20px;
  ${fontSize(19)};
  line-height: 1.1;
  color: ${({ alternative }) =>
    alternative === false || alternative === 'grad'
      ? brandColours.primary
      : standardColours.white};

  ${minBreakpointQuery.tiny`
    padding-left: 25px;
  `}

  ${minBreakpointQuery.small`
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(25)};
  `}

  ${({ inFeaturedCategories, isMoreLink }) => {
    if (inFeaturedCategories && !isMoreLink) {
      return css`
        ${minBreakpointQuery.medium`
          width: calc(45% + 10px);
        `}
      `;
    } else if (isMoreLink) {
      return css`
        max-width: 200px;
      `;
    } else {
      return css`
        padding-right: 20px;

        ${minBreakpointQuery.tiny`
          padding-right: 25px;
        `}

        ${minBreakpointQuery.small`
          padding-top: 15px;
          padding-right: 30px;
          padding-left: 30px;
        `}

        ${minBreakpointQuery.large`
          padding-top: 20px;
          padding-right: 40px;
          padding-left: 40px;
        `}
      `;
    }
  }};
`;

const EquipmentCategoryCard = ({
  title,
  slug,
  image,
  alternative,
  inFeaturedCategories,
  firstInFeaturedCategories,
  isMoreLink,
  ...props
}) => {
  const url = slug ? `equipment/${slug}` : 'equipment';

  return (
    <StyledEquipmentCategoryCard
      alternative={alternative}
      inFeaturedCategories={inFeaturedCategories}
      isMoreLink={isMoreLink}
      onClick={() => {
        navigate(buildUrl(url));
      }}
      {...props}
    >
      {image && (
        <StyledImageWrapper
          inFeaturedCategories={inFeaturedCategories}
          firstInFeaturedCategories={firstInFeaturedCategories}
        >
          <Img fluid={image.fluid} alt={image.alt} />
        </StyledImageWrapper>
      )}
      <StyledHeading
        alternative={alternative}
        inFeaturedCategories={inFeaturedCategories}
        isMoreLink={isMoreLink}
      >
        <Link to={url}>{title}</Link>
      </StyledHeading>
    </StyledEquipmentCategoryCard>
  );
};

export default EquipmentCategoryCard;

export const EquipmentCategoryCardFragment = graphql`
  fragment EquipmentCategoryCardFragment on DatoCmsEquipmentCategory {
    id
    slug
    title
    image: featuredImage {
      fluid(
        maxWidth: 480
        imgixParams: { auto: "compress", fit: "crop", w: "480" }
      ) {
        ...GatsbyDatoCmsFluid
      }
    }
  }
`;
