import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontSize,
  fontWeights,
  standardTransition,
} from '../styles';
import { Container, Heading, Slider, Button } from './ui';

const StyledFeaturedCranes = styled.section`
  ${sectionMargins()};
  overflow: hidden;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

// moved up for hover
const StyledContent = styled.div`
  margin-top: 20px;
  opacity: 0;
  transition: ${standardTransition('opacity')};

  ${minBreakpointQuery.small`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `}
`;

const StyledSlider = styled(Slider)`
  margin: auto;
  max-width: 600px;

  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    transform: scale(0.8);
    transition: ${standardTransition('transform')};

    &.slick-current {
      transform: scale(1);

      ${StyledContent} {
        opacity: 1;
      }
    }
  }
`;

const StyledItem = styled.article`
  padding: 10px 60px;
  text-align: center;
`;

const StyledName = styled.p`
  color: ${brandColours.primary};
  ${fontSize(25)};
  font-weight: ${fontWeights.bold};

  ${minBreakpointQuery.small`
    ${fontSize(28)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(31)};
  `}
`;

const StyledTitle = styled.h3`
  margin-top: 12px;
  ${fontSize(21)};

  ${minBreakpointQuery.small`
    ${fontSize(23)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(25)};
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const FeaturedCranes = ({ heading, items }) => {
  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <StyledFeaturedCranes>
      <Container>
        <StyledHeading>{heading}</StyledHeading>
        <StyledSlider sliderOptions={sliderOptions}>
          {items.map(({ id, title, slug, name, image }) => (
            <StyledItem key={id}>
              {image && <Img fluid={image.fluid} alt={image.alt} />}
              <StyledContent>
                {name && <StyledName>{name}</StyledName>}
                <StyledTitle>{title}</StyledTitle>
                <StyledButton to={`equipment/${slug}`}>View Crane</StyledButton>
              </StyledContent>
            </StyledItem>
          ))}
        </StyledSlider>
      </Container>
    </StyledFeaturedCranes>
  );
};

export default FeaturedCranes;
