import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container, LineLink, Slider } from './ui';
import ProjectCard from './ProjectCard';
import ProjectTypeSelector from './ProjectTypeSelector';

const StyledFeaturedProjects = styled.div`
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  display: grid;
  grid-template-columns: 100%;
  gap: 30px;

  ${minBreakpointQuery.smedium`
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 20px));
    gap: 40px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(2, calc(50% - 30px));
    gap: 60px;
  `};

  ${minBreakpointQuery.large`
    grid-template-columns: repeat(2, calc(50% - 40px));
    gap: 80px;
  `};

  ${minBreakpointQuery.xlarge`
    grid-template-columns: repeat(2, calc(50% - 50px));
    gap: 100px;
  `};
`;

const StyledItems = styled.section``;

const StyledHeader = styled.header`
  margin-bottom: 20px;

  ${minBreakpointQuery.tiny`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledHeading = styled.h2`
  ${fluidFontSize(
    '25px',
    '35px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};

  ${maxBreakpointQuery.tiny`
    margin-bottom: 15px;
  `}

  ${minBreakpointQuery.tiny`
    margin-right: 20px;
  `}
`;

const StyledSlider = styled(Slider)`
  .slick-slide {
    ${fontSize(0)};
  }

  .slick-dots {
    padding: 0 20px 20px;
    text-align: right;
    background-color: ${brandColours.primary};

    ${minBreakpointQuery.medium`
      padding-right: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
    `}

    ${minBreakpointQuery.xlarge`
      padding-right: 40px;
      padding-bottom: 40px;
      padding-left: 40px;
    `}

    li {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      ${fontSize(0)};

      ${minBreakpointQuery.small`
        margin-left: 15px;
      `}

      ${minBreakpointQuery.large`
        margin-left: 20px;
      `}

      button {
        height: 12px;
        width: 12px;
        ${fontSize(0)};
        background-color: ${standardColours.white};
        border: none;
        border-radius: 50%;
        cursor: pointer;

        ${minBreakpointQuery.large`
          height: 14px;
          width: 14px;
        `}
      }

      &:first-child {
        margin-left: 0;
      }

      &.slick-active {
        button {
          background-color: ${brandColours.secondary};
        }
      }
    }
  }
`;

const FeaturedProjects = ({ heading, items }) => {
  const sliderOptions = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
  };

  return (
    <StyledFeaturedProjects>
      <Container>
        <StyledInner>
          <StyledItems>
            <StyledHeader>
              <StyledHeading>{heading}</StyledHeading>
              <LineLink to="projects">View all</LineLink>
            </StyledHeader>
            <StyledSlider sliderOptions={sliderOptions}>
              {items.map(({ id, title, slug, image }) => (
                <ProjectCard
                  key={id}
                  title={title}
                  slug={slug}
                  image={image}
                  inSlider={true}
                />
              ))}
            </StyledSlider>
          </StyledItems>
          <ProjectTypeSelector />
        </StyledInner>
      </Container>
    </StyledFeaturedProjects>
  );
};

export default FeaturedProjects;
