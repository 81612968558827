import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { brandColours } from '../styles/variables';
import {
  fontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
} from '../styles/tools';
import { Link } from './ui';

const StyledNewsCard = styled.article`
  display: flex;
  flex-direction: column;

  ${minBreakpointQuery.mlarge`
    display: grid;
  `};
`;

const StyledTime = styled.time`
  position: relative;
  align-self: start;
  order: 1;
  color: ${brandColours.primary};

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${brandColours.primary};
    position: absolute;
    top: 50%;
    transform: translateX(calc(100% + 20px));
  }

  ${maxBreakpointQuery.mlarge`
    margin-top: 12px;
    margin-bottom: 12px;
  `};

  ${minBreakpointQuery.mlarge`
    order: 0;
    align-self: end;
    justify-center: center;
    transform-origin: 0 50%;
    margin-bottom: 100px;
    transform: translateX(50%) rotate(-90deg);
    
    &:before {
      width: calc(100% - 20px);
      transform: translateX(calc(-100% - 20px));
    }
  `}
`;

const StyledThumbnail = styled.div`
  ${minBreakpointQuery.mlarge`
    max-width: 380px;
  `};

  ${minBreakpointQuery.large`
    max-width: 440px;
  `};

  ${minBreakpointQuery.xlarge`
    max-width: 480px;
  `};
`;

const StyledTitle = styled.h3`
  order: 2;
  ${fontSize(20)};

  ${minBreakpointQuery.small`
    ${fontSize(25)};
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(30)};
  `}

  ${minBreakpointQuery.mlarge`
    order: 0;
    margin-top: 28px;
    grid-column-start: 2;
  `}

  ${minBreakpointQuery.large`
    ${fontSize(35)};
  `}
`;

const NewsCard = ({ title, slug, image, meta }) => (
  <StyledNewsCard>
    <StyledTime dateTime={meta.robotDate}>{meta.humanDate}</StyledTime>
    <StyledThumbnail>
      <Img fluid={image.fluid} alt={image.alt} />
    </StyledThumbnail>
    <StyledTitle>
      <Link to={`news/` + slug}>{title}</Link>
    </StyledTitle>
  </StyledNewsCard>
);

export default NewsCard;

export const NewsCardThumbFragment = graphql`
  fragment NewsCardThumbFragment on DatoCmsFileField {
    fluid(
      maxWidth: 480
      maxHeight: 600
      imgixParams: { auto: "compress", fit: "crop", w: "480", h: "600" }
    ) {
      ...GatsbyDatoCmsFluid
    }
    alt
  }
`;
