import React from 'react';
import styled from 'styled-components';
import {
  fontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
  sectionPaddings,
  brandColours,
  fontWeights,
  standardColours,
} from '../styles';
import { Button, Container, Heading, Svg } from './ui';
import downloadIcon from '../images/download.svg';

const StyledDownloadStrip = styled.section`
  ${sectionPaddings('40px', '70px')};
  background-color: ${brandColours.secondary};
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;

  ${minBreakpointQuery.medium`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
  `};
`;

const StyledHeading = styled(Heading)`
  color: ${brandColours.primary};
`;

const StyledText = styled.p`
  ${fontSize(15)};
  color: ${brandColours.primary};
  margin-top: 20px;

  ${minBreakpointQuery.small`
    ${fontSize(16)};
  `};

  ${minBreakpointQuery.medium`
    ${fontSize(17)};
    margin-top: 30px;
  `};

  ${minBreakpointQuery.large`
    ${fontSize(18)};
  `};
`;

const StyledButton = styled(Button)`
  font-weight: ${fontWeights.regular};
  white-space: nowrap;

  &:hover {
    background-color: ${brandColours.quinary};
    color: ${standardColours.white};
  }

  ${maxBreakpointQuery.medium`
    margin-top: 25px;
  `};
`;

const StyledSvg = styled(Svg)`
  width: 18px;
  height: 18px;
  margin-left: 25px;
`;

const DownloadStrip = ({ heading, text, file }) => (
  <StyledDownloadStrip>
    <Container>
      <StyledInner>
        <div>
          <StyledHeading>{heading}</StyledHeading>
          {text && <StyledText>{text}</StyledText>}
        </div>
        <div>
          <StyledButton href={file.url} target="_blank">
            Download
            <StyledSvg title={`Download`} image={downloadIcon} />
          </StyledButton>
        </div>
      </StyledInner>
    </Container>
  </StyledDownloadStrip>
);

export default DownloadStrip;
