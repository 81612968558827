import React from 'react';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, Heading, Slider } from './ui';
import CallbackButton from './CallbackButton';
import CallCta from './CallCta';
import EquipmentCard from './EquipmentCard';

const StyledFeaturedEquipment = styled.section`
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  ${({ removeCta }) => {
    if (!removeCta) {
      return css`
        ${minBreakpointQuery.medium`
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        `}
      `;
    }
  }};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}

  ${({ removeCta }) => {
    if (removeCta) {
      return css`
        text-align: center;
      `;
    } else {
      return css`
        ${maxBreakpointQuery.medium`
          text-align: center;
        `}

        ${minBreakpointQuery.medium`
          order: 1;
          margin-top: 10px;
          margin-right: 20px;
        `}
      `;
    }
  }};
`;

const StyledSlider = styled(Slider)`
  width: 100%;

  .slick-list {
    margin: 0 30px;
  }

  ${minBreakpointQuery.medium`
    order: 3;
  `}
`;

const StyledItem = styled(EquipmentCard)`
  padding: 10px 30px;

  ${minBreakpointQuery.small`
    padding: 15px 35px;
  `}

  ${minBreakpointQuery.large`
    padding: 20px 40px;
  `}
`;

const StyledCta = styled.div`

  ${maxBreakpointQuery.medium`
    margin-top: 20px;
    justify-content: center;
  `}

  ${maxBreakpointQuery.tsmall`
    text-align: center;
  `}

  ${minBreakpointQuery.tsmall`
    display: flex;
    align-items: center;
  `}

  ${minBreakpointQuery.medium`
    order: 2;
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledButton = styled(CallbackButton)`

  ${maxBreakpointQuery.tsmall`
    margin-bottom: 20px;
    width: 100%;
  `}

  ${minBreakpointQuery.tsmall`
    margin-right: 20px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-right: 30px;
  `}
`;

const FeaturedEquipment = ({ heading, items, removeCta }) => {
  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: breakpointSizes.smedium - 1,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <StyledFeaturedEquipment>
      <Container>
        <StyledInner removeCta={removeCta}>
          <StyledHeading removeCta={removeCta}>{heading}</StyledHeading>
          <StyledSlider sliderOptions={sliderOptions}>
            {items.map(({ id, title, slug, price, images }) => (
              <StyledItem
                key={id}
                title={title}
                slug={slug}
                price={price}
                image={images[0]}
              />
            ))}
          </StyledSlider>
          {!removeCta && (
            <StyledCta>
              <StyledButton />
              <CallCta />
            </StyledCta>
          )}
        </StyledInner>
      </Container>
    </StyledFeaturedEquipment>
  );
};

export default FeaturedEquipment;
