import React from 'react';
import styled from 'styled-components';
import { Link } from './ui';
import Img from 'gatsby-image';
import {
  brandColours,
  minBreakpointQuery,
  standardColours,
  standardTransition,
} from '../styles';
import { graphql } from 'gatsby';

const StyledPressReleaseCard = styled.article`
  background-color: ${standardColours.white};
  height: 100%;
`;

const StyledMeta = styled.div`
  ${props =>
    props.inverted === true
      ? `
    background-color: ${brandColours.primary};
    color: ${standardColours.white};
  `
      : `
    background-color: ${standardColours.white};
  `};

  padding: 18px 28px;

  ${minBreakpointQuery.small`
    padding: 22px 32px;
  `};

  ${minBreakpointQuery.mlarge`
    padding: 26px 48px;
  `};
`;

const StyledThumbnail = styled.div`
  position: relative;
  background-image: linear-gradient(
    155deg,
    ${brandColours.secondary},
    ${brandColours.primary}
  );
`;

const StyledImg = styled(Img)`
  opacity: 1;
  transition: ${standardTransition('opacity')};

  &:hover {
    opacity: 0.25;

    ~ span {
      opacity: 1;
    }
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  display: block;
`;

const StyledLinkText = styled.span`
  position: absolute;
  opacity: 0;
  color: ${standardColours.white};
  transition: ${standardTransition('opacity')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 2px));
  pointer-events: none;
  border-bottom: 2px solid ${standardColours.white};
  white-space: nowrap;
`;

const PressReleaseCard = ({ title, slug, image, inverted }) => (
  <StyledPressReleaseCard>
    <StyledThumbnail>
      <StyledLink to={`press-releases/` + slug}>
        <StyledImg fluid={image.fluid} alt={image.alt} />
        <StyledLinkText>View Press Release</StyledLinkText>
      </StyledLink>
    </StyledThumbnail>
    <StyledMeta inverted={inverted}>
      <h3>
        <Link to={`press-releases/` + slug}>{title}</Link>
      </h3>
    </StyledMeta>
  </StyledPressReleaseCard>
);

export default PressReleaseCard;

export const PressReleaseThumbFragment = graphql`
  fragment PressReleaseThumbFragment on DatoCmsFileField {
    fluid(
      maxWidth: 520
      maxHeight: 300
      imgixParams: { auto: "compress", fit: "crop", w: "520", h: "300" }
    ) {
      ...GatsbyDatoCmsFluid
    }
    alt
  }
`;
