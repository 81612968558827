import React from 'react';
import { navigate, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  standardTransition,
} from '../styles';
import { Link } from './ui';
import { buildUrl } from '../utils';

// moved up for hover
const StyledImage = styled(Img)`
  transition: ${standardTransition('transform')};
`;

const StyledProjectCard = styled.article`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    ${StyledImage} {
      transform: scale(1.1);
    }
  }
`;

const StyledTitle = styled.h3`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  color: ${standardColours.white};
  ${fontSize(22)};
  background-color: ${brandColours.primary};

  ${minBreakpointQuery.tsmall`
    padding: 30px;
    ${fontSize(24)};
  `}

  ${minBreakpointQuery.smedium`
    padding: 20px;
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.medium`
    padding: 30px;
    ${fontSize(24)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(26)};
  `}

  ${minBreakpointQuery.xlarge`
    padding: 40px;
    ${fontSize(28)};
  `}

  ${({ inSlider }) => {
    if (!inSlider) {
      return css`
        right: 30px;

        ${minBreakpointQuery.small`
          right: 40px;
        `}

        ${minBreakpointQuery.large`
          right: 50px;
        `}
      `;
    }
  }};
`;

const ProjectCard = ({
  title,
  slug,
  image: { fluid, alt },
  inSlider,
  ...props
}) => (
  <StyledProjectCard
    onClick={() => {
      navigate(buildUrl(`projects/${slug}`));
    }}
    {...props}
  >
    <StyledImage fluid={fluid} alt={alt} />
    <StyledTitle inSlider={inSlider}>
      <Link to={`projects/${slug}`}>{title}</Link>
    </StyledTitle>
  </StyledProjectCard>
);

export default ProjectCard;

export const ProjectCardFragment = graphql`
  fragment ProjectCardFragment on DatoCmsProject {
    id
    title
    slug
    image: bannerImage {
      fluid(
        maxHeight: 600
        maxWidth: 700
        imgixParams: { auto: "compress", fit: "crop", h: "600", w: "700" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
  }
`;
