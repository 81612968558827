import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container } from './ui';
import PageLinkCard from './PageLinkCard';

const StyledPageLinkCards = styled.section`
  ${sectionMargins()};
`;

const StyledItems = styled.div`
  display: grid;
  grid-gap: 20px;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    grid-gap: 30px;
  `}
`;

const PageLinkCards = ({ items, pathname }) =>
  items.length > 0 && (
    <StyledPageLinkCards>
      <Container>
        <StyledItems>
          {items.map(item => (
            <PageLinkCard key={item.id} data={item} pathname={pathname} />
          ))}
        </StyledItems>
      </Container>
    </StyledPageLinkCards>
  );

export default PageLinkCards;
