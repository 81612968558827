import React from 'react';
import { navigate, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
  fontWeights,
  zIndexLayers,
  standardTransition,
} from '../styles';
import { Link } from './ui';
import { buildUrl } from '../utils';

const StyledPageLinkCard = styled.article`
  position: relative;
  overflow: hidden;
  width: 100%;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to top,
      ${brandColours.primary} 25%,
      ${standardColours.transparent} 100%
    );
    transform: translateY(25%);
    transition: ${standardTransition('transform')};
  }

  &:hover {
    &:after {
      transform: translateY(0);
    }
  }
`;

const StyledImage = styled(Img)`
  min-height: 200px;

  ${minBreakpointQuery.tiny`
    min-height: 240px;
  `}

  ${minBreakpointQuery.small`
    min-height: 280px;
  `}
`;

const StyledTextContainer = styled.div`
  position: absolute;
  right: 30px;
  bottom: 40px;
  left: 30px;
  z-index: ${zIndexLayers.first};
  color: ${standardColours.white};
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};

  ${minBreakpointQuery.medium`
    right: 45px;
    bottom: 50px;
    left: 45px;
  `}

  ${minBreakpointQuery.large`
    right: 60px;
    bottom: 60px;
    left: 60px;
  `}
`;

const StyledTitle = styled.h3`
  display: block;
  ${fontSize(22)};

  ${minBreakpointQuery.tiny`
    ${fontSize(26)};
  `}

  ${minBreakpointQuery.small`
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(26)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(30)};
  `}
`;

const StyledText = styled.p`
  margin-top: 6px;
  font-weight: ${fontWeights.regular};
  ${fontSize(17)};

  ${minBreakpointQuery.tiny`
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.small`
    ${fontSize(17)};
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(23)};
  `}
`;

const PageLinkCard = ({
  data: {
    slug,
    title,
    image: { fluid, alt },
    subpageCardTitle,
    subpageCardText,
  },
  pathname = '',
  ...props
}) => (
  <StyledPageLinkCard
    onClick={() => {
      navigate(buildUrl(pathname + slug));
    }}
    {...props}
  >
    <StyledImage fluid={fluid} alt={alt} />
    <StyledTextContainer>
      <StyledTitle>
        <Link to={pathname + slug}>{subpageCardTitle || title}</Link>
      </StyledTitle>
      {subpageCardText && <StyledText>{subpageCardText}</StyledText>}
    </StyledTextContainer>
  </StyledPageLinkCard>
);

export default PageLinkCard;

export const PageLinkCardFragment = graphql`
  fragment PageLinkCardFragment on DatoCmsPage {
    id
    slug
    treeParent {
      slug
      treeParent {
        slug
        treeParent {
          slug
        }
      }
    }
    title
    image: bannerImage {
      fluid(
        maxHeight: 320
        maxWidth: 560
        imgixParams: { auto: "compress", fit: "crop", h: "320", w: "560" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
    subpageCardTitle
    subpageCardText
    position
  }
`;
