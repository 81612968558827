import React from 'react';
import { navigate, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { minBreakpointQuery, brandColours, fontSize } from '../styles';
import { Link } from './ui';
import { buildUrl } from '../utils';

const StyledEquipmentCard = styled.article`
  text-align: center;
  cursor: pointer;
`;

const StyledContent = styled.div`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const StyledTitle = styled.h3`
  ${fontSize(19)};

  ${minBreakpointQuery.small`
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(25)};
  `}
`;

const StyledPrice = styled.p`
  margin-top: 10px;
  color: ${brandColours.primary};

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}
`;

const EquipmentCard = ({
  title,
  slug,
  price,
  image: { fluid, alt },
  ...props
}) => (
  <StyledEquipmentCard
    onClick={() => {
      navigate(buildUrl(`equipment/${slug}`));
    }}
    {...props}
  >
    <Img fluid={fluid} alt={alt} />
    <StyledContent>
      <StyledTitle>
        <Link to={`equipment/${slug}`}>{title}</Link>
      </StyledTitle>
      {price && <StyledPrice>{price}</StyledPrice>}
    </StyledContent>
  </StyledEquipmentCard>
);

export default EquipmentCard;

export const EquipmentCardFragment = graphql`
  fragment EquipmentCardFragment on DatoCmsEquipment {
    id
    title
    slug
    price
    images {
      fluid(
        maxHeight: 380
        maxWidth: 560
        imgixParams: { auto: "compress", fit: "crop", h: "380", w: "560" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
  }
`;
