import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import { Container } from './ui';

const StyledImageBlock = styled.section`
  ${sectionMargins()};
`;

const ImageBlock = ({ image: { fluid, alt }, contain }) => (
  <StyledImageBlock>
    {contain ? (
      <Container>
        <Img fluid={fluid} alt={alt} />
      </Container>
    ) : (
      <Img fluid={fluid} alt={alt} />
    )}
  </StyledImageBlock>
);

export default ImageBlock;
