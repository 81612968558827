import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionPaddings,
  brandColours,
} from '../styles';
import { Container, Heading, Slider, LineLink } from './ui';
import TeamCard from './TeamCard';

const StyledFeaturedTeam = styled.section`
  ${sectionPaddings()};
  background-color: ${brandColours.quaternary};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledSlider = styled(Slider)`
  margin: 0 -10px;
`;

const StyledItem = styled(TeamCard)`
  padding: 0 10px;
`;

const StyledFooter = styled.footer`
  margin-top: 20px;
  text-align: right;

  ${minBreakpointQuery.small`
    margin-top: 30px;
  `};

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `};
`;

const FeaturedTeam = ({ heading, items }) => {
  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: breakpointSizes.mlarge - 1,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: breakpointSizes.tsmall - 1,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <StyledFeaturedTeam>
      <Container>
        <StyledHeading>{heading}</StyledHeading>
        <StyledSlider sliderOptions={sliderOptions}>
          {items.map(({ id, title, slug, role, image }) => (
            <StyledItem
              key={id}
              name={title}
              slug={slug}
              role={role}
              image={image}
            />
          ))}
        </StyledSlider>
        <StyledFooter>
          <LineLink to="our-people">Meet the team</LineLink>
        </StyledFooter>
      </Container>
    </StyledFeaturedTeam>
  );
};

export default FeaturedTeam;
