import React from 'react';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import { Container, Video } from './ui';

const StyledVideoBlock = styled.section`
  ${sectionMargins()};
`;

const StyledVideo = styled(Video)`
  width: 100%;

  video {
    width: 100%;
  }
`;

const VideoBlock = ({ video }) => (
  <StyledVideoBlock>
    <Container>
      <StyledVideo data={video} />
    </Container>
  </StyledVideoBlock>
);

export default VideoBlock;
