import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionPaddings,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';
import CallbackButton from './CallbackButton';
import CallCta from './CallCta';

const StyledAccordionBlock = styled.section`
  ${sectionPaddings()};
  background-color: ${brandColours.quaternary};
`;

const StyledHeader = styled.header`
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledCta = styled.div`
  ${maxBreakpointQuery.medium`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.tiny`
    display: flex;
    align-items: center;
  `}

  ${minBreakpointQuery.medium`
    margin-left: 20px;
  `}
`;

const StyledButton = styled(CallbackButton)`

  ${maxBreakpointQuery.tiny`
    margin-bottom: 20px;
    width: 100%;
  `}

  ${minBreakpointQuery.tiny`
    margin-right: 20px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-right: 30px;
    width: 100%;
  `}
`;

const StyledItem = styled.article`
  margin: 20px 0;
  padding: 20px 30px;
  border: 1px solid ${brandColours.primary};

  ${minBreakpointQuery.small`
    padding: 25px 40px;
  `}

  ${minBreakpointQuery.large`
    padding: 30px 50px;
  `}
`;

const StyledSubHeading = styled.h3`
  position: relative;
  padding-right: 30px;
  font-weight: ${fontWeights.semibold};
  ${fontSize(21)};
  line-height: 1.5;
  cursor: pointer;

  ${minBreakpointQuery.small`
    padding-right: 35px;
    ${fontSize(23)};
  `}

  ${minBreakpointQuery.large`
    padding-right: 40px;
    ${fontSize(25)};
  `}

  &:after {
    content: ${({ display }) => (display ? "'-'" : "'+'")};
    position: absolute;
    top: 50%;
    right: 0;
    color: ${brandColours.primary};
    font-weight: ${fontWeights.regular};
    ${fontSize(28)};
    transform: translateY(-50%);

    ${minBreakpointQuery.small`
      ${fontSize(32)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(36)};
    `}
  }
`;

const StyledContent = styled(HtmlContent)`
  display: ${({ display }) => (display ? 'block' : 'none')};
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const AccordionBlock = ({ heading, items }) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <StyledAccordionBlock>
      <Container>
        <StyledHeader>
          <Heading>{heading}</Heading>
          <StyledCta>
            <StyledButton />
            <CallCta />
          </StyledCta>
        </StyledHeader>
        {items.map(({ heading, content }, i) => {
          const display = activeItem === i;

          return (
            <StyledItem>
              <StyledSubHeading
                display={display}
                onClick={() => setActiveItem(display ? undefined : i)}
              >
                {heading}
              </StyledSubHeading>
              <StyledContent html={content} display={display} />
            </StyledItem>
          );
        })}
      </Container>
    </StyledAccordionBlock>
  );
};

export default AccordionBlock;

export const AccordionBlockFragment = graphql`
  fragment AccordionBlockFragment on DatoCmsAccordionInstance {
    heading
    accordionItems {
      id
      heading
      content
    }
  }
`;
