import React from 'react';
import { graphql } from 'gatsby';
import AccordionBlock from './AccordionBlock';
import ContactForm from './ContactForm';
import ContentBlock from './ContentBlock';
import DownloadStrip from './DownloadStrip';
import FeaturedCranes from './FeaturedCranes';
import FeaturedEquipmentCategories from './FeaturedEquipmentCategories';
import FeaturedEquipment from './FeaturedEquipment';
import FeaturedProjects from './FeaturedProjects';
import FeaturedTeam from './FeaturedTeam';
import ImageBlock from './ImageBlock';
import ImageSlider from './ImageSlider';
import LatestNews from './LatestNews';
import LatestPressReleases from './LatestPressReleases';
import LogosStrip from './LogosStrip';
import MediaContentBlock from './MediaContentBlock';
import PageLinkCards from './PageLinkCards';
import ProjectTypeSelector from './ProjectTypeSelector';
import TabsBlock from './TabsBlock';
import TestimonialBlock from './TestimonialBlock';
import VideoBlock from './VideoBlock';

const ModularBlocks = ({ items, subpages, pathname }) =>
  items.map(item => (
    <React.Fragment key={item.id}>
      {item.model.apiKey === 'accordion_modular_block' && (
        <AccordionBlock
          heading={item.instance.heading}
          items={item.instance.accordionItems}
        />
      )}
      {item.model.apiKey === 'contact_form_modular_block' && (
        <ContactForm heading={item.heading} text={item.text} />
      )}
      {item.model.apiKey === 'contained_image_modular_block' && (
        <ImageBlock image={item.image} contain={true} />
      )}
      {item.model.apiKey === 'content_modular_block' && (
        <ContentBlock
          html={item.content}
          contain={item.contain}
          twoColumns={item.twoColumns}
          highlight={item.highlight}
        />
      )}
      {item.model.apiKey === 'download_strip_modular_block' && (
        <DownloadStrip
          heading={item.heading}
          text={item.text}
          file={item.file}
        />
      )}
      {item.model.apiKey === 'external_video_modular_block' && (
        <VideoBlock video={item.externalVideo} />
      )}
      {item.model.apiKey === 'featured_cranes_modular_block' && (
        <FeaturedCranes heading={item.heading} items={item.items} />
      )}
      {item.model.apiKey === 'featured_equipment_categories_modular_block' && (
        <FeaturedEquipmentCategories
          heading={item.heading}
          items={item.items}
        />
      )}
      {item.model.apiKey === 'featured_equipment_modular_block' && (
        <FeaturedEquipment heading={item.heading} items={item.items} />
      )}
      {item.model.apiKey === 'featured_logos_modular_block' && (
        <LogosStrip heading={item.heading} items={item.logos} />
      )}
      {item.model.apiKey === 'featured_projects_modular_block' && (
        <FeaturedProjects heading={item.heading} items={item.items} />
      )}
      {item.model.apiKey === 'featured_team_modular_block' && (
        <FeaturedTeam heading={item.heading} items={item.items} />
      )}
      {item.model.apiKey === 'image_content_modular_block' && (
        <MediaContentBlock
          media={item.image}
          heading={item.heading}
          content={item.content}
          link={{
            page: item.linkPage,
            text: item.linkText,
          }}
          flip={item.flip}
        />
      )}
      {item.model.apiKey === 'image_slider_modular_block' && (
        <ImageSlider items={item.images} />
      )}
      {item.model.apiKey === 'latest_news_modular_block' && (
        <LatestNews heading={item.heading} />
      )}
      {item.model.apiKey === 'latest_press_releases_modular_block' && (
        <LatestPressReleases heading={item.heading} />
      )}
      {item.model.apiKey === 'page_link_cards_modular_block' && (
        <PageLinkCards items={item.pageLinkCards} />
      )}
      {item.model.apiKey === 'project_type_selector_modular_block' && (
        <ProjectTypeSelector fullWidth={true} />
      )}
      {item.model.apiKey === 'subpage_link_cards_modular_block' && (
        <PageLinkCards items={subpages} pathname={pathname} />
      )}
      {item.model.apiKey === 'tabs_modular_block' && (
        <TabsBlock heading={item.instance.heading} items={item.instance.tabs} />
      )}
      {item.model.apiKey === 'testimonial_modular_block' && (
        <TestimonialBlock
          heading={item.heading}
          quote={item.quote}
          author={item.author}
        />
      )}
      {item.model.apiKey === 'video_content_modular_block' && (
        <MediaContentBlock
          media={item.video}
          heading={item.heading}
          content={item.content}
          link={{
            page: item.linkPage,
            text: item.linkText,
          }}
          flip={item.flip}
        />
      )}
      {item.model.apiKey === 'video_modular_block' && (
        <VideoBlock video={item.video} />
      )}
    </React.Fragment>
  ));

export default ModularBlocks;

export const ModularBlockFragments = graphql`
  fragment AccordionModularBlockFragment on DatoCmsAccordionModularBlock {
    id
    model {
      apiKey
    }
    instance {
      ...AccordionBlockFragment
    }
  }

  fragment ContactFormModularBlockFragment on DatoCmsContactFormModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
  }

  fragment ContainedImageModularBlockFragment on DatoCmsContainedImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      fluid(
        maxWidth: 1140
        imgixParams: { auto: "compress", fit: "crop", w: "1140" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
  }

  fragment ContentModularBlockFragment on DatoCmsContentModularBlock {
    id
    model {
      apiKey
    }
    content
    twoColumns
    highlight
    contain
  }

  fragment DownloadStripModularBlockFragment on DatoCmsDownloadStripModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    file {
      url
    }
  }

  fragment ExternalVideoModularBlockFragment on DatoCmsExternalVideoModularBlock {
    id
    model {
      apiKey
    }
    externalVideo {
      width
      height
      url
      providerUid
      provider
    }
  }

  fragment FeaturedCranesModularBlockFragment on DatoCmsFeaturedCranesModularBlock {
    id
    model {
      apiKey
    }
    heading
    items {
      id
      title
      slug
      name
      image: featuredImage {
        fluid(
          maxWidth: 480
          imgixParams: { auto: "compress", fit: "crop", w: "480" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
  }

  fragment FeaturedEquipmentCategoriesModularBlockFragment on DatoCmsFeaturedEquipmentCategoriesModularBlock {
    id
    model {
      apiKey
    }
    heading
    items {
      ...EquipmentCategoryCardFragment
    }
  }

  fragment FeaturedEquipmentModularBlockFragment on DatoCmsFeaturedEquipmentModularBlock {
    id
    model {
      apiKey
    }
    heading
    items {
      ...EquipmentCardFragment
    }
  }

  fragment FeaturedLogosModularBlockFragment on DatoCmsFeaturedLogosModularBlock {
    id
    model {
      apiKey
    }
    heading
    logos {
      alt
      url
    }
  }

  fragment FeaturedProjectsModularBlockFragment on DatoCmsFeaturedProjectsModularBlock {
    id
    model {
      apiKey
    }
    heading
    items {
      ...ProjectCardFragment
    }
  }

  fragment FeaturedTeamModularBlockFragment on DatoCmsFeaturedTeamModularBlock {
    id
    model {
      apiKey
    }
    heading
    items {
      ...TeamCardFragment
    }
  }

  fragment ImageContentModularBlockFragment on DatoCmsImageContentModularBlock {
    id
    model {
      apiKey
    }
    image {
      fluid(
        maxHeight: 750
        maxWidth: 1000
        imgixParams: { auto: "compress", fit: "crop", h: "750", w: "1000" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
    heading
    content
    linkPage {
      ...LinkFragment
    }
    linkText
    flip
  }

  fragment ImageSliderModularBlockFragment on DatoCmsImageSliderModularBlock {
    id
    model {
      apiKey
    }
    images {
      fluid(
        maxHeight: 800
        maxWidth: 1600
        imgixParams: { auto: "compress", fit: "crop", h: "800", w: "1600" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
  }

  fragment LatestNewsModularBlockFragment on DatoCmsLatestNewsModularBlock {
    id
    model {
      apiKey
    }
    heading
  }

  fragment LatestPressReleasesModularBlockFragment on DatoCmsLatestPressReleasesModularBlock {
    id
    model {
      apiKey
    }
    heading
  }

  fragment PageLinkCardsModularBlockFragment on DatoCmsPageLinkCardsModularBlock {
    id
    model {
      apiKey
    }
    pageLinkCards {
      ...PageLinkCardFragment
    }
  }

  fragment ProjectTypeSelectorModularBlockFragment on DatoCmsProjectTypeSelectorModularBlock {
    id
    model {
      apiKey
    }
  }

  fragment SubpageLinkCardsModularBlockFragment on DatoCmsSubpageLinkCardsModularBlock {
    id
    model {
      apiKey
    }
  }

  fragment TabsModularBlockFragment on DatoCmsTabsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      tabs {
        id
        heading
        content
      }
    }
  }

  fragment TestimonialModularBlockFragment on DatoCmsTestimonialModularBlock {
    id
    model {
      apiKey
    }
    heading
    quote
    author
  }

  fragment VideoContentModularBlockFragment on DatoCmsVideoContentModularBlock {
    id
    model {
      apiKey
    }
    video {
      format
      video {
        thumbnailUrl
        mp4Url
      }
    }
    heading
    content
    linkPage {
      ...LinkFragment
    }
    linkText
    flip
  }

  fragment VideoModularBlockFragment on DatoCmsVideoModularBlock {
    id
    model {
      apiKey
    }
    video {
      video {
        thumbnailUrl
        mp4Url
      }
    }
  }
`;
