import React from 'react';
import styled from 'styled-components';
import { Container, Heading } from './ui';
import { minBreakpointQuery, sectionMargins } from '../styles';
import EquipmentCategoryCard from './EquipmentCategoryCard';

const StyledFeaturedEquipmentCategoriesSection = styled.section`
  ${sectionMargins()}
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 30px;
  text-align: center;

  ${minBreakpointQuery.tsmall`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 60px;
  `}
`;

const StyledInner = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 215px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-auto-rows: 230px;
  `}

  ${minBreakpointQuery.large`
    gap: 25px;
  `}
`;

const StyledItem = styled(EquipmentCategoryCard)`
  ${minBreakpointQuery.medium`
    &:first-child {
      grid-row: 1 / 3;
    }
  `}
`;

const FeaturedEquipmentCategories = ({ heading, items }) => (
  <StyledFeaturedEquipmentCategoriesSection>
    <Container>
      <StyledHeading>{heading}</StyledHeading>
      <StyledInner>
        {items.map(({ id, title, slug, image }, i) => (
          <StyledItem
            key={id}
            title={title}
            slug={slug}
            image={image}
            alternative={i < 2}
            inFeaturedCategories={true}
            firstInFeaturedCategories={i === 0}
          />
        ))}
        <StyledItem
          title={`More Equipment`}
          alternative={`grad`}
          inFeaturedCategories={true}
          isMoreLink={true}
        />
      </StyledInner>
    </Container>
  </StyledFeaturedEquipmentCategoriesSection>
);

export default FeaturedEquipmentCategories;
