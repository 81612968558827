import React from 'react';
import { useInView } from 'react-intersection-observer';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionPaddings,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  fluidFontSize,
  standardTransition,
} from '../styles';
import { Container, Button, Slider } from './ui';
import CallCta from './CallCta';

const StyledBanner = styled.section`
  background-color: ${brandColours.primary};
  overflow: hidden;

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        ${sectionPaddings()};
      `;
    }
  }};
`;

const StyledContainer = styled(Container)`
  ${({ isHomepage }) => {
    if (!isHomepage) {
      return css`
        padding-right: 0;
        padding-left: 0;
        max-width: 100%;
      `;
    }
  }};
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.smedium`
    display: grid;
    gap: 30px;
    align-items: center;
  `}

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        ${minBreakpointQuery.smedium`
          grid-template-columns: 1.5fr 1fr;
        `}

        ${minBreakpointQuery.medium`
          grid-template-columns: repeat(2, 1fr);
        `}
      `;
    } else {
      return css`
        ${minBreakpointQuery.smedium`
          grid-template-columns: repeat(2, 1fr);
        `}
      `;
    }
  }};
`;

const StyledContentWrapper = styled.div`
  position: relative;
  z-index: 1;

  ${({ isHomepage }) => {
    if (!isHomepage) {
      return css`
        padding: 0 30px;

        ${minBreakpointQuery.smedium`
          margin-left: auto;
          padding-top: 100px;
          padding-bottom: 100px;
          width: 100%;
          max-width: 580px;
        `}
      `;
    }
  }};
`;

const StyledContent = styled.div`
  ${({ isHomepage }) => {
    if (!isHomepage) {
      return css`
        margin: 40px 0;
      `;
    }
  }};
`;

const StyledHeading = styled.h1`
  color: ${standardColours.white};
  font-weight: ${fontWeights.bold};
  ${fluidFontSize(
    '30px',
    '46px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};

  span {
    display: block;
    margin-bottom: 10px;
    color: ${brandColours.secondary};
    font-weight: ${fontWeights.regular};
    ${fluidFontSize(
      '18px',
      '25px',
      breakpointSizes.tiny,
      breakpointSizes.xxxxlarge
    )};
  }
`;

const StyledRole = styled.p`
  margin-top: 5px;
  color: ${brandColours.secondary};
  ${fontSize(15)};
  text-transform: uppercase;
`;

const StyledPostTime = styled.time`
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: ${standardColours.white};
  font-weight: ${fontWeights.medium};
  white-space: nowrap;

  &:before {
    content: '';
    display: inline-block;
    margin-right: 12px;
    height: 1px;
    width: 60px;
    background-color: ${brandColours.secondary};

    ${minBreakpointQuery.small`
      margin-right: 14px;
      width: 80px;
    `}

    ${minBreakpointQuery.medium`
      margin-right: 16px;
      width: 100px;
    `}

    ${minBreakpointQuery.large`
      margin-right: 18px;
      width: 120px;
    `}
  }
`;

const StyledText = styled.p`
  margin-top: 20px;
  color: ${standardColours.white};
  line-height: 1.9;
`;

const StyledCta = styled.div`
  margin-top: 30px;

  ${maxBreakpointQuery.tsmall`
    text-align: center;
  `}

  ${minBreakpointQuery.tsmall`
    display: flex;
    align-items: center;
  `}
`;

const StyledButton = styled(Button)`
  color: ${brandColours.primary};
  background-color: ${brandColours.secondary};

  ${maxBreakpointQuery.tsmall`
    margin-bottom: 20px;
    width: 100%;
  `}

  ${minBreakpointQuery.tsmall`
    margin-right: 20px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-right: 30px;
  `}

  &:hover {
    color: ${brandColours.tertiary};
  }
`;

const StyledImageWrapper = styled.div`
  position: relative;

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        &:before {
          content: '';
          position: absolute;
          top: -60px;
          right: -60px;
          bottom: -60px;
          left: -60px;
          background: radial-gradient(
            ellipse at center,
            rgba(29, 110, 75) 0%,
            rgba(0, 64, 36, 0.46) 70%
          );
          border-radius: 50%;
        }
      `;
    } else {
      return css`
        ${minBreakpointQuery.smedium`
          height: 100%;
        `}
      `;
    }
  }};
`;

const StyledImage = styled(Img)`
  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        margin: 30px auto 0;
        max-width: 480px;
        opacity: 0;
        transform: translateY(-40px);
        transition: ${standardTransition('opacity', '0.6s', 'ease-out')},
          ${standardTransition('transform', '0.6s', 'ease-out')};
        transition-delay: 0.4s;

        ${minBreakpointQuery.smedium`
          max-width: 560px;
        `}
      `;
    } else {
      return css`
        position: relative;

        ${maxBreakpointQuery.smedium`
          max-height: 320px;
        `};

        ${minBreakpointQuery.smedium`
          margin-left: auto;
          height: 100%;
          max-width: 1000px;
        `};

        ${minBreakpointQuery.mlarge`
          min-height: 500px;
        `}

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: linear-gradient(
            to bottom,
            rgba(0, 64, 36, 1) 0,
            rgba(0, 64, 36, 0.1) 100%
          );

          ${minBreakpointQuery.smedium`
            background: linear-gradient(
              to right,
              rgba(0, 64, 36, 1) 0,
              rgba(0, 64, 36, 0.1) 100%
            );
          `}
        }
      `;
    }
  }};

  ${({ inView }) => {
    if (inView) {
      return css`
        opacity: 1;
        transform: translateY(0);
      `;
    }
  }};
`;

const StyledUsps = styled(Slider)`
  margin-top: 30px;
  padding-left: 10px;
  max-width: 100%;
  border-left: 2px solid ${brandColours.secondary};
`;

const StyledUsp = styled.p`
  color: ${standardColours.white};
  font-weight: ${fontWeights.bold};
  ${fontSize(14)};
  text-transform: uppercase;

  ${minBreakpointQuery.mlarge`
    ${fontSize(16)};
  `}
`;

const Banner = ({
  overline,
  heading,
  role,
  firstPublishedAt,
  text,
  link,
  image,
  usps,
  isHomepage,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    vertical: true,
  };

  return (
    <StyledBanner isHomepage={isHomepage}>
      <StyledContainer isHomepage={isHomepage}>
        <StyledInner isHomepage={isHomepage}>
          <StyledContentWrapper isHomepage={isHomepage}>
            <StyledContent>
              <StyledHeading>
                {overline && <span>{overline} </span>}
                {heading}
              </StyledHeading>
              {role && <StyledRole>{role}</StyledRole>}
              {firstPublishedAt && (
                <StyledPostTime dateTime={firstPublishedAt.robotDate}>
                  {firstPublishedAt.humanDate}
                </StyledPostTime>
              )}
              {text && <StyledText>{text}</StyledText>}
              {link && link.page && (
                <StyledCta>
                  <StyledButton to={link.page.slug} linkData={link.page}>
                    {link.text}
                  </StyledButton>
                  <CallCta alt={true} />
                </StyledCta>
              )}
            </StyledContent>
          </StyledContentWrapper>
          {image && (
            <StyledImageWrapper isHomepage={isHomepage} ref={ref}>
              <StyledImage
                fluid={image.fluid}
                fadeIn={false}
                loading="eager"
                alt={image.alt}
                isHomepage={isHomepage}
                inView={inView}
              />
            </StyledImageWrapper>
          )}
        </StyledInner>
        {usps && usps.length > 0 && (
          <StyledUsps sliderOptions={sliderOptions}>
            {usps.map(({ text }) => (
              <StyledUsp>{text}</StyledUsp>
            ))}
          </StyledUsps>
        )}
      </StyledContainer>
    </StyledBanner>
  );
};

export default Banner;

export const BannerImageFragment = graphql`
  fragment HomepageBannerImageFragment on DatoCmsFileField {
    fluid(
      maxWidth: 560
      imgixParams: { auto: "compress", fit: "crop", w: "560" }
    ) {
      ...GatsbyDatoCmsFluid
    }
    alt
  }

  fragment SubpageBannerImageFragment on DatoCmsFileField {
    fluid(
      maxHeight: 600
      maxWidth: 1000
      imgixParams: { auto: "compress", fit: "crop", h: "600", w: "1000" }
    ) {
      ...GatsbyDatoCmsFluid
    }
    alt
  }
`;
