import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontSize,
} from '../styles';
import { Container, Slider } from './ui';

const StyledImageSlider = styled.section`
  ${sectionMargins()};
`;

const StyledContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
`;

const StyledSlider = styled(Slider)`
  position: relative;

  .slick-slide {
    ${fontSize(0)};
  }

  .slick-dots {
    padding: 10px 20px;
    text-align: center;
    background-color: ${brandColours.primary};

    ${minBreakpointQuery.small`
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    `}

    ${minBreakpointQuery.medium`
      padding: 15px 30px;
    `}

    ${minBreakpointQuery.large`
      padding: 20px 40px;
    `}

    li {
      display: inline-block;
      vertical-align: middle;
      margin: 6px;
      ${fontSize(0)};

      ${minBreakpointQuery.small`
        margin: 8px;
      `}

      ${minBreakpointQuery.large`
        margin: 10px;
      `}

      button {
        height: 12px;
        width: 12px;
        ${fontSize(0)};
        background-color: ${standardColours.white};
        border: none;
        border-radius: 50%;
        cursor: pointer;

        ${minBreakpointQuery.small`
          height: 14px;
          width: 14px;
        `}

        ${minBreakpointQuery.large`
          height: 16px;
          width: 16px;
        `}
      }

      &.slick-active {
        button {
          background-color: ${brandColours.secondary};
        }
      }
    }
  }
`;

const ImageSlider = ({ items }) => {
  const sliderOptions = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
  };

  return (
    <StyledImageSlider>
      <StyledContainer wide={true}>
        <StyledSlider {...sliderOptions}>
          {items.map(({ fluid, alt }, i) => (
            <Img key={`slider-image-${i}`} fluid={fluid} alt={alt} />
          ))}
        </StyledSlider>
      </StyledContainer>
    </StyledImageSlider>
  );
};

export default ImageSlider;
