import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  brandColours,
  standardColours,
  maxBreakpointQuery,
  minBreakpointQuery,
} from '../styles/';
import { Container, Heading, LineLink } from './ui';
import PressReleaseCard from './PressReleaseCard';

const StyledLatestPressReleases = styled.section`
  background-color: ${brandColours.primary};
  padding: 80px 0;

  ${minBreakpointQuery.small`
    padding: 100px 0;
  `};

  ${minBreakpointQuery.smedium`
    padding-bottom: 120px;
  `};

  ${minBreakpointQuery.mlarge`
    padding-bottom: 140px;
  `};
`;

const StyledHeading = styled(Heading)`
  color: ${standardColours.white};
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 60px;
  `};

  ${minBreakpointQuery.mlarge`
    margin-bottom: 80px;
  `};

  &:before {
    content: '';
    display: block;
    width: 2px;
    height: 112px;
    background-color: ${brandColours.secondary};
    margin: -110px auto 20px;

    ${minBreakpointQuery.small`
      height: 132px;
      margin-top: -140px;
    `};
  }
`;

const StyledList = styled.ol`
  display: flex;

  ${maxBreakpointQuery.small`
    flex-direction: column;
  `};

  ${minBreakpointQuery.small`
    justify-content: space-between;
    gap: 20px;
  `};
`;

const StyledItem = styled.li`
  width: 100%;
  max-width: 480px;

  ${maxBreakpointQuery.small`
    align-self: center;
    margin-top: 40px;
  `};
`;

const StyledFooter = styled.footer`
  margin-top: 20px;
  text-align: right;

  ${minBreakpointQuery.small`
    margin-top: 30px;
  `};

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `};
`;

const StyledLink = styled(LineLink)`
  color: ${standardColours.white};

  &:before {
    background-color: ${brandColours.secondary};
  }

  &:hover {
    color: ${brandColours.secondary};
  }
`;

const LatestPressReleases = ({ heading }) => {
  const {
    allDatoCmsPressRelease: { nodes: items },
  } = useStaticQuery(graphql`
    query LatestPressReleasesQuery {
      allDatoCmsPressRelease(
        limit: 2
        sort: { fields: meta___firstPublishedAt, order: DESC }
      ) {
        nodes {
          ...PressReleaseQueryFragment
        }
      }
    }
  `);

  return (
    items.length > 0 && (
      <StyledLatestPressReleases>
        <Container>
          <StyledHeading>{heading}</StyledHeading>
          <StyledList>
            {items.map(item => (
              <StyledItem>
                <PressReleaseCard
                  title={item.title}
                  slug={item.slug}
                  image={item.bannerImage}
                  inverted={false}
                />
              </StyledItem>
            ))}
          </StyledList>
          <StyledFooter>
            <StyledLink to="press-releases">View all</StyledLink>
          </StyledFooter>
        </Container>
      </StyledLatestPressReleases>
    )
  );
};

export default LatestPressReleases;

export const PressReleaseQueryFragment = graphql`
  fragment PressReleaseQueryFragment on DatoCmsPressRelease {
    slug
    title
    bannerImage {
      ...PressReleaseThumbFragment
    }
  }
`;
