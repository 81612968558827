import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Heading } from './ui';
import { buildUrl } from '../utils';

const StyledProjectTypeSelector = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ fullWidth }) => (fullWidth ? '50px 0' : '30px')};
  background-color: ${brandColours.secondary};

  ${minBreakpointQuery.small`
    padding: ${({ fullWidth }) => (fullWidth ? '60px 0' : '40px')};
  `}

  ${minBreakpointQuery.large`
    padding: ${({ fullWidth }) => (fullWidth ? '70px 0' : '50px')};
  `}

  ${minBreakpointQuery.xlarge`
    padding: ${({ fullWidth }) => (fullWidth ? '80px 0' : '60px')};
  `}
`;

const StyledInner = styled.div`
  ${({ fullWidth }) => {
    if (fullWidth) {
      return css`
        margin-right: auto;
        margin-left: auto;
        padding-left: 30px;
        padding-right: 30px;
        width: 100%;
        max-width: 1200px;

        ${minBreakpointQuery.small`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 60px;
          align-items: center;
        `}

        ${minBreakpointQuery.medium`
          gap: 80px;
        `}

        ${minBreakpointQuery.large`
          gap: 100px;
        `}

        ${minBreakpointQuery.xlarge`
          gap: 120px;
        `}
      `;
    }
  }};
`;

const StyledHeading = styled(Heading)`
  color: ${brandColours.primary};
  text-align: center;

  ${({ fullWidth }) => {
    if (fullWidth) {
      return css`
        ${minBreakpointQuery.small`
          text-align: left;
        `}
      `;
    }
  }};
`;

const StyledSelectWrapper = styled.label`
  position: relative;
  display: inline-block;
  margin-top: 15px;
  width: 100%;
  background-color: ${standardColours.white};

  ${({ fullWidth }) => {
    if (fullWidth) {
      return css`
        ${minBreakpointQuery.small`
          margin-top: 0;
        `}
      `;
    } else {
      return css`
        margin-top: 15px;

        ${minBreakpointQuery.small`
          margin-top: 20px;
        `}

        ${minBreakpointQuery.large`
          margin-top: 25px;
        `}
      `;
    }
  }};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 24px;
    margin-top: -3px;
    height: 12px;
    width: 12px;
    border: 2px solid ${brandColours.tertiary};
    border-top: 0;
    border-left: 0;
    pointer-events: none;
    transform: translateY(-50%) rotate(45deg);
  }
`;

const StyledLabel = styled.span`
  ${visuallyHidden()};
`;

const StyledSelect = styled.select`
  padding: 12px 60px 12px 20px;
  height: 50px;
  width: 100%;
  color: ${brandColours.tertiary};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.bold};
  ${fontSize(16)};
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;

  ${minBreakpointQuery.small`
    height: 60px;
  `}

  ${minBreakpointQuery.large`
    height: 70px;
  `}
`;

const ProjectTypeSelector = ({ fullWidth }) => {
  const {
    allDatoCmsProjectCategory: { nodes },
  } = useStaticQuery(graphql`
    query ProjectTypeSelectorQuery {
      allDatoCmsProjectCategory {
        nodes {
          id
          title
          slug
        }
      }
    }
  `);

  return (
    nodes.length > 0 && (
      <StyledProjectTypeSelector fullWidth={fullWidth}>
        <StyledInner fullWidth={fullWidth}>
          <StyledHeading fullWidth={fullWidth}>
            What type of project are you needing help with?
          </StyledHeading>
          <StyledSelectWrapper for="project-type-select" fullWidth={fullWidth}>
            <StyledLabel>Project Type</StyledLabel>
            <StyledSelect
              name="project-type-select"
              id="project-type-select"
              onChange={event =>
                navigate(buildUrl(`projects/${event.target.value}`))
              }
            >
              <option value="select" selected="true" disabled>
                Please select
              </option>
              {nodes.map(({ id, title, slug }) => (
                <option key={id} value={slug}>
                  {title}
                </option>
              ))}
            </StyledSelect>
          </StyledSelectWrapper>
        </StyledInner>
      </StyledProjectTypeSelector>
    )
  );
};

export default ProjectTypeSelector;
