import React from 'react';
import { navigate, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  standardTransition,
} from '../styles';
import { Link } from './ui';
import { buildUrl } from '../utils';

// moved up for hover
const StyledContent = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px 30px;
  color: ${standardColours.white};
  transform: translateY(100%);
  transition: ${standardTransition('transform')};

  ${minBreakpointQuery.large`
    padding: 30px 40px;
  `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      135deg,
      ${brandColours.secondary} 0%,
      ${brandColours.secondary} 50%,
      ${brandColours.primary} 100%
    );
    opacity: 0.9;
  }
`;

const StyledTeamCard = styled.article`
  cursor: pointer;
  overflow: hidden;

  &:hover {
    ${StyledContent} {
      transform: translateY(0);
    }
  }
`;

const StyledInner = styled.div`
  position: relative;
`;

const StyledHeading = styled.h3`
  position: relative;
  ${fontSize(20)};

  ${minBreakpointQuery.small`
    ${fontSize(22)};
  `}
`;

const StyledText = styled.p`
  position: relative;
  margin-top: 6px;
  ${fontSize(14)};

  ${minBreakpointQuery.small`
    ${fontSize(16)};
  `}
`;

const TeamCard = ({ name, slug, role, image: { fluid, alt }, ...props }) => (
  <StyledTeamCard
    onClick={() => {
      navigate(buildUrl(`our-people/${slug}`));
    }}
    {...props}
  >
    <StyledInner>
      <Img fluid={fluid} alt={alt} />
      <StyledContent>
        <StyledHeading>
          <Link to={`our-people/${slug}`}>{name}</Link>
        </StyledHeading>
        <StyledText>{role}</StyledText>
      </StyledContent>
    </StyledInner>
  </StyledTeamCard>
);

export default TeamCard;

export const TeamCardFragment = graphql`
  fragment TeamCardFragment on DatoCmsTeam {
    id
    title
    slug
    role
    image: bannerImage {
      fluid(
        maxHeight: 480
        maxWidth: 460
        imgixParams: { auto: "compress", fit: "crop", h: "480", w: "460" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
  }
`;
