import React from 'react';
import styled from 'styled-components';
import { Container, Heading } from './ui';
import NewsCard from './NewsCard';
import { minBreakpointQuery, sectionMargins } from '../styles/tools';
import { graphql, useStaticQuery } from 'gatsby';

const StyledLatestNewsSection = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 30px;

  ${minBreakpointQuery.mlarge`
    max-width: 30%;
  `};
`;

const StyledList = styled.ol`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `};

  ${minBreakpointQuery.medium`
    gap: 30px;
  `};
`;

const StyledItem = styled.li`
  display: flex;

  &:nth-child(even) {
    justify-content: end;
  }

  ${minBreakpointQuery.mlarge`
    display: block;
  
    &:last-child {
      margin-top: -60px;
    }
  `};
`;

const LatestNews = ({ heading }) => {
  const {
    allDatoCmsNews: { nodes: items },
  } = useStaticQuery(graphql`
    query LatestNewsQuery {
      allDatoCmsNews(
        limit: 2
        sort: { fields: meta___firstPublishedAt, order: DESC }
      ) {
        nodes {
          ...NewsQueryFragment
        }
      }
    }
  `);

  return (
    items.length > 1 && (
      <StyledLatestNewsSection>
        <Container>
          <StyledHeading>{heading}</StyledHeading>
          <StyledList>
            {items.map(item => (
              <StyledItem>
                <NewsCard
                  title={item.title}
                  slug={item.slug}
                  image={item.bannerImage}
                  meta={item.meta}
                />
              </StyledItem>
            ))}
          </StyledList>
        </Container>
      </StyledLatestNewsSection>
    )
  );
};

export default LatestNews;

export const NewsQueryFragment = graphql`
  fragment NewsQueryFragment on DatoCmsNews {
    title
    slug
    meta {
      humanDate: firstPublishedAt(formatString: "DD.MM.YYYY")
      robotDate: firstPublishedAt
    }
    bannerImage {
      ...NewsCardThumbFragment
    }
  }
`;
