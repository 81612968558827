import React from 'react';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, Video, Heading, HtmlContent, Button } from './ui';

const StyledMediaContentBlock = styled.section`
  ${sectionMargins()};
`;

const StyledContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;

    ${({ flip }) => {
      if (flip) {
        return css`
          flex-direction: row-reverse;
        `;
      }
    }}
  `}
`;

const StyledBlock = styled.div`
  ${minBreakpointQuery.medium`
    margin: auto;
    width: 50%;
  `}

  &:last-child {
    ${minBreakpointQuery.medium`
      align-self: center;
    `}
  }
`;

const StyledImage = styled(Img)`
  height: 100%;

  ${maxBreakpointQuery.medium`
    max-height: 400px;
  `}
`;

const StyledContent = styled.div`
  padding: 30px;

  ${minBreakpointQuery.medium`

    ${({ flip }) => {
      if (flip) {
        return css`
          margin-left: auto;
          padding-right: 40px;
        `;
      } else {
        return css`
          padding-left: 40px;
        `;
      }
    }}
  `}

  ${minBreakpointQuery.large`

    ${({ flip }) => {
      if (flip) {
        return css`
          padding-right: 50px;
        `;
      } else {
        return css`
          padding-left: 50px;
        `;
      }
    }}
  `}

  ${minBreakpointQuery.xlarge`
    max-width: 600px;

    ${({ flip }) => {
      if (flip) {
        return css`
          padding-right: 60px;
        `;
      } else {
        return css`
          padding-left: 60px;
        `;
      }
    }}
  `}

  ${minBreakpointQuery.xxlarge`

    ${({ flip }) => {
      if (flip) {
        return css`
          padding-right: 70px;
        `;
      } else {
        return css`
          padding-left: 70px;
        `;
      }
    }}
  `}

  ${minBreakpointQuery.xxxxlarge`

    ${({ flip }) => {
      if (flip) {
        return css`
          padding-right: 80px;
        `;
      } else {
        return css`
          padding-left: 80px;
        `;
      }
    }}
  `}
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;

  ${minBreakpointQuery.large`
    margin-bottom: 25px;
  `}
`;

const MediaContentBlock = ({ media, heading, content, link, flip }) => {
  return (
    <StyledMediaContentBlock>
      <StyledContainer wide={true}>
        <StyledInner flip={flip}>
          <StyledBlock>
            {media.video ? (
              <Video data={media} autoPlay={true} />
            ) : (
              <StyledImage fluid={media.fluid} alt={media.alt} />
            )}
          </StyledBlock>
          <StyledBlock>
            <StyledContent flip={flip}>
              <StyledHeading>{heading}</StyledHeading>
              {content && <HtmlContent html={content} />}
              {link && link.page && (
                <Button to={link.page.slug} linkData={link.page}>
                  {link.text}
                </Button>
              )}
            </StyledContent>
          </StyledBlock>
        </StyledInner>
      </StyledContainer>
    </StyledMediaContentBlock>
  );
};

export default MediaContentBlock;
