import React from 'react';
import styled from 'styled-components';
import { breakpointSizes, minBreakpointQuery, sectionPaddings, standardColours, brandColours, fontWeights, fluidFontSize } from '../styles';
import { Container } from './ui';

const StyledTestimonialBlock = styled.section`
  ${sectionPaddings('60px', '160px')};
  color: ${standardColours.white};
  text-align: center;
  background-color: ${brandColours.primary};
`;

const StyledHeading = styled.h2`
  font-weight: ${fontWeights.medium};
  ${fluidFontSize(
    '25px',
    '35px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledContent = styled.blockquote`
  margin: 20px 0 0;

  ${minBreakpointQuery.small`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `}
`;

const StyledQuote = styled.p`
  font-weight: ${fontWeights.bold};
  ${fluidFontSize(
    '26px',
    '38px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  line-height: 1.5;
`;

const StyledAuthor = styled.footer`
  margin-top: 20px;
  ${fluidFontSize(
    '18px',
    '25px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};

  ${minBreakpointQuery.small`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `}
`;

const TestimonialBlock = ({
  heading,
  quote,
  author
}) => (
  <StyledTestimonialBlock>
    <Container narrow={true}>
      <StyledHeading>{heading}</StyledHeading>
      <StyledContent>
        <StyledQuote>"{quote}"</StyledQuote>
        {author && <StyledAuthor>{author}</StyledAuthor>}
      </StyledContent>
    </Container>
  </StyledTestimonialBlock>
);

export default TestimonialBlock;