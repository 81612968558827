import React from 'react';
import styled from 'styled-components';
import { Container, Heading } from './ui';
import { minBreakpointQuery, sectionMargins, standardColours } from '../styles';

const StyledLogosStrip = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 10px;

  ${minBreakpointQuery.small`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 30px;
  `}
`;

const StyledImagesWrap = styled.div`
  position: relative;
  overflow: auto;
  margin-left: -20px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 20px;
  }

  &:before {
    left: 0;
    background: linear-gradient(
      to left,
      ${standardColours.transparent} 0%,
      ${standardColours.white} 100%
    );
  }

  &:after {
    right: 0;
    background: linear-gradient(
      to right,
      ${standardColours.transparent} 0%,
      ${standardColours.white} 100%
    );
  }
`;

const StyledImages = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  padding: 20px;
  width: 100%;
`;

const StyledImage = styled.img`
  padding: 0 8px;
  max-height: 70px;
  max-width: 220px;

  ${minBreakpointQuery.large`
    padding-right: 10px;
    padding-left: 10px;
    max-height: 90px;
    max-width: 260px;
  `}
`;

const LogosStrip = ({ heading, items }) => (
  <StyledLogosStrip>
    <Container>
      <StyledHeading>{heading}</StyledHeading>
      <StyledImagesWrap>
        <StyledImages>
          {items.map(({ url, alt }, i) => (
            <StyledImage key={`logo-${i}`} src={url} alt={alt} loading="lazy" />
          ))}
        </StyledImages>
      </StyledImagesWrap>
    </Container>
  </StyledLogosStrip>
);

export default LogosStrip;
